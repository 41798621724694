import React from 'react'

export default function ContactForm() {
    return (
            <form method="post" action="#" data-netlify="true" name="contact" data-netlify-recaptcha="true">
              <script src="https://www.google.com/recaptcha/api.js" async defer></script>
              <input type="hidden" name="form-name" value="contact" />
              <div className="field half first">
                <label htmlFor="name">Name</label>
                <input type="text" name="name" id="name" required />
              </div>
              <div className="field half">
                <label htmlFor="email">Email</label>
                <input type="email" name="email" id="email" required />
              </div>
              <div className="field">
                <label htmlFor="message">Message</label>
                <textarea name="message" id="message" rows="4" required />
              </div>
              <div class="g-recaptcha" data-sitekey="6LeRIMkZAAAAAIwP8NybYuQ_aLIzkrBYwiZ-l42U"></div>
              <ul className="actions">
                <li><input type="submit" value="Send Message" className="special" /></li>
              </ul>
            </form>
    )
}