import React from 'react'
import SocialIcons from './SocialIcons'

export default function ListenSection() {
    return (
        <>
            <h2 className="major">Listen</h2>
            <div id='fb-video'>
                <iframe title="homebound" src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2F1893791770914148%2Fvideos%2F357545531697165%2F&show_text=false&appId" 
                    allowTransparency="true" allow="encrypted-media" allowFullScreen="true">
                </iframe>
            </div>
            <div id='fb-video'>
                <iframe title="top"src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2F1893791770914148%2Fvideos%2F1928074724152519%2F&width=734&show_text=false&height=411&appId" 
                    allowTransparency="true" allow="encrypted-media" allowFullScreen="true">
                </iframe>
            </div>
            <p>If you like these make sure you follow us on Facebook and Instagram to hear more great tunes!</p>
            <SocialIcons />
        </>
    )
}