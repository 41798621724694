import React from 'react'
import PropTypes from 'prop-types'
import ContactSection from './sections/ContactSection'
import AboutSection from './sections/AboutSection'
import ListenSection from './sections/ListenSection'

class Main extends React.Component {
  render() {

    let close = <div className="close" role="button" tabIndex="0" onClick={() => {
      this.props.onCloseArticle()
      }} 
      onKeyPress={() => {
        this.props.onCloseArticle()
        }}>> </div>

    return (
      <div ref={this.props.setWrapperRef} id="main" style={this.props.timeout ? {display: 'flex'} : {display: 'none'}}>
        <article  id="listen" 
                    className={`${this.props.article === 'listen' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} 
                    style={{display:'none'}}>
          <ListenSection />
          {close}
        </article>

        <article  id="about" 
                  className={`${this.props.article === 'about' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} 
                  style={{display:'none'}}>
          <AboutSection />
          {close}
        </article>

        <article  id="contact" 
                  className={`${this.props.article === 'contact' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} 
                  style={{display:'none'}}>
          <ContactSection />
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main