import React from 'react'
import ContactForm from './ContactForm'
import SocialIcons from './SocialIcons'

export default function ContactSection() {
    return (
        <>
            <h2 className="major">Contact</h2>
            <ContactForm />
            <SocialIcons />
        </>
    )
}