import React from 'react'
import SocialIcons from './SocialIcons'
import groupPic from '../../images/groupPic.jpg'

export default function AboutSection() {
    return (
        <>
            <h2 className="major">About</h2>
            <span className="image main"><img src={groupPic} alt="" /></span>
            <p>
            That is our goal, that is our aim, that is our way; to make you move, to make you shake and dance like nobody is watching.
            <br></br><br></br>
            <b><u>Band Members</u></b>
            <br></br>
            Ronan McCann (Banjo/Mandolin/Vocals), 
            <br></br>
            Brent Murty (Guitar/Vocals), 
            <br></br>
            Padhraic Colton (Guitar/Vocals), 
            <br></br>
            Darragh Tracey (Percussion)
            <br></br><br></br>
            <SocialIcons />
            </p>
        </>
    )
}